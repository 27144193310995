











































import { Component, Prop, Vue } from 'vue-property-decorator'
import { CartLineItemWithPrice, CartWithItemsPrice } from '@/api/shop/types/shop'
import VsPlanDetails from '@/modules/account/components/VsPlanDetails/Index.vue'
import VsSeparator from '@/components/VsSeparator/Index.vue'
import MyAccordion from '@/components/MyAccordion/Index.vue'
import { formatNumber } from '@/utils/formatter'
import { formatEmailCreditsRateFromMetadata, formatPriceName } from '@/utils/shop'
import moment from 'moment'

@Component({
    name: 'VsTrialCard',
    components: {
        VsPlanDetails,
        VsSeparator,
        MyAccordion,
    },
})

export default class extends Vue {
    @Prop() cart!: CartWithItemsPrice | null
    @Prop() cartPlan!: CartLineItemWithPrice | null

    get planGroup () {
        return this.cartPlan?.price.productRulesPreset?.group || ''
    }

    private formatPriceName (price: any) {
        return formatPriceName(price)
    }

    get expireDate () {
        return moment().add(7, 'days').unix()
    }

    get emailQuantity () {
        if (!this.cart || !this.cart.items[0]) return 0
        return this.cart.items[0].price
    }

    get cartRules () {
        if (!this.cart || !this.cart.items[0]) return []
        return this.cart.items[0].price.productRulesPreset?.value
    }

    private showDetails = false

    private formatEmailCreditsRateFromPrice (price: any) {
        if (!price) return ''
        return formatNumber(parseInt(price.metadata.rateCredits)) + ' email/' + formatEmailCreditsRateFromMetadata(price.metadata)
    }
}
