











































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsPriceAddableCard',
})
export default class extends Vue {
    @Prop({ default: '' }) title!: string
    @Prop({ default: '' }) subtitle!: string
    @Prop({ default: '' }) description!: string
    @Prop({ default: 0 }) quantity!: number
    @Prop({ default: 'default', required: false }) variant!: string
}
