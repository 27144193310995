





































































































































































































import {
    CartLineItemWithPrice,
    CartWithItemsPrice,
} from '@/api/shop/types/shop'
import { formatNumber } from '@/utils/formatter'
import {
    calculateAndFormatItemPriceAndRate,
    calculateAndFormatItemPrice,
    formatPriceName,
    formatPriceRateValue,
    formatEmailCreditsRateFromMetadata,
} from '@/utils/shop'
import VsCartLineItem from '@/modules/shop/components/VsCartLineItem/Index.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import { get } from 'lodash'

@Component({
    name: 'VsCartBox',
    components: {
        VsCartLineItem,
    },
})
export default class extends Vue {
    @Prop({ default: null, required: true }) cart!: CartWithItemsPrice | null
    @Prop({ default: null, required: true }) cartPlan!: CartLineItemWithPrice | null
    @Prop({ default: () => [], required: true }) populateUserItems!: CartLineItemWithPrice[]
    @Prop({ default: false, required: true }) cartRecursivity!: any
    @Prop({ default: false, required: false }) loading!: boolean
    @Prop({ default: false, required: true }) currentStep!: string
    @Prop({ default: false, required: true }) hasInvoiceData!: boolean
    @Prop({ default: false, required: false }) canCheckout!: boolean
    @Prop({ default: false, required: false }) hasPaymentMethods!: boolean
    @Prop({ default: false, required: false }) checkPaymentMethods!: boolean
    @Prop({ default: false, required: false }) userCart!: boolean
    @Prop({ default: false, required: false }) isTrialCart!: boolean
    // @Prop({ default: true, required: false }) canPurchase!: boolean
    private showUserItems = false
    private acceptTerms = false

    get cartTotal (): number {
        if (!this.cart) return 0
        let total = 0
        this.cart.items.forEach(el => {
            total += el.price.value * el.quantity
        })
        return total
    }

    get cartTotalWithCurrentItems () {
        if (!this.cart) return 0
        let total = 0
        this.cart.items.forEach(el => {
            total += el.price.value * el.quantity
        })
        this.populateUserItems.forEach(el => {
            total += el.price.value * el.quantity
        })
        return total
    }

    get canGoToCheckout () {
        const valid = !this.hasInvoiceData || !this.canCheckout || !this.acceptTerms
        if (this.checkPaymentMethods) return valid || !this.hasPaymentMethods
        return valid
    }

    get shopUser () {
        return UserModule.shopUser
    }

    get userSubscriptionItems (): any[] {
        return get(this.shopUser, 'subscription.items', [])
    }

    get cartItems (): any[] {
        return get(this.cart, 'items', [])
    }

    get userCredits () {
        return UserModule.userCredits
    }

    get userEmailCredits () {
        const emailCredits = this.userCredits.filter(el => el.key === 'email')
        if (emailCredits.length > 0) return emailCredits[0].value
    }

    get sameUserItems () {
        if (this.userSubscriptionItems.length === 0 || this.cartItems.length === 0) return false
        if (this.cartItems.length === this.userSubscriptionItems.length) {
            const mappedCartItems = this.cartItems.map((item: any) => item.priceId)
            const mappedUserItems = this.userSubscriptionItems.map((item: any) => item.priceId)
            if ((mappedCartItems.sort().join(',') === mappedUserItems.sort().join(','))) {
                return true
            }
        }
        return false
    }

    get cannotPurchase () {
        return this.sameUserItems && this.userEmailCredits > 0
    }

    private formatEmailCreditsRateFromPrice (price: any) {
        return formatNumber(parseInt(price.metadata.rateCredits)) + ' email /' + formatEmailCreditsRateFromMetadata(price.metadata)
    }

    private formatPriceName (price: any) {
        return formatPriceName(price)
    }

    private formatPriceRateValue (price: any) {
        return formatPriceRateValue(price)
    }

    private calculateAndFormatItemPriceAndRate (item: any) {
        return calculateAndFormatItemPriceAndRate(item)
    }

    private calculateAndFormatItemPrice (item: any) {
        return calculateAndFormatItemPrice(item)
    }
}
