
















































import { Component, Vue } from 'vue-property-decorator'
import VsNumber from '@/components/VsNumber/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { addItemsToCart, updateCartItem, removeItemToCart, createCart } from '@/api/shop'

@Component({
    name: 'VsManageCartItemModal',
    components: {
        VsNumber,
    },
})
export default class extends Vue {
    private show = false
    private cartId = ''
    private title = ''
    private priceId = ''
    private canDelete = false
    private quantity = 1
    private disableQuantity = false

    public async openModal (cartId: string, priceId: any, title: string, quantity: number, disableQuantity?: boolean) {
        this.disableQuantity = false
        if (disableQuantity) this.disableQuantity = disableQuantity
        this.cartId = cartId
        this.title = title
        this.priceId = priceId
        this.quantity = quantity || 1
        this.canDelete = !!quantity
        this.show = true
    }

    public closeModal () {
        this.show = false
        this.cartId = ''
        this.title = ''
        this.priceId = ''
        this.canDelete = false
        this.quantity = 1
    }

    private handleChange () {
        if (this.canDelete) {
            this.updateCartItem()
        } else {
            if (this.cartId) {
                this.addItemsToCart()
            } else {
                this.createCart()
            }
        }
    }

    private async addItemsToCart () {
        if (!this.cartId || !this.priceId) return
        try {
            await addItemsToCart(this.cartId, {
                items: [{
                    priceId: this.priceId,
                    quantity: this.quantity,
                }],
            })
            this.$emit('cart-item-updated')
            this.show = false
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: 'Impossibile aggiungere questo elemento al carrello',
                message: 'La quantità impostata potrebbe essere non permessa',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
    }

    private async updateCartItem () {
        if (!this.cartId || !this.priceId) return
        try {
            await updateCartItem(this.cartId, this.priceId, {
                quantity: this.quantity,
            })
            this.$emit('cart-item-updated')
            this.show = false
        } catch (e) {
            console.log(e)
        }
    }

    private async removeItemToCart () {
        if (!this.cartId && !this.priceId) return
        try {
            await removeItemToCart(this.cartId, this.priceId)
            this.$emit('cart-item-updated')
            this.show = false
        } catch (e) {
            console.log(e)
        }
    }

    private async createCart () {
        try {
            await createCart({
                items: [{
                    priceId: this.priceId,
                    quantity: this.quantity,
                }],
            })
            this.$emit('cart-item-updated')
            this.show = false
        } catch (e) {
            console.log(e)
        }
    }
}
