























































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
    PriceListPopulated,
    CartWithItemsPrice,
    ShopUser,
    CartLineItemWithPrice,
    PriceWithProductRulesPreset,
    CustomerData,
} from '@/api/shop/types/shop'
import {
    checkoutSession,
    getPriceList,
    getActiveCart,
    removeItemToCart,
    createCart,
    deleteAllCartItems,
    getPricesAddable,
    getInvoiceData,
    getShopUser,
    customerPortal,
} from '@/api/shop'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsInvoiceData from '@/components/VsInvoiceData/Index.vue'
import VsSidebarLayout from '@/components/VsSidebarLayout/Index.vue'
import VsPriceAddableCard from '@/modules/shop/components/VsPriceAddableCard/Index.vue'
import VsManageCartItemModal from '@/modules/shop/components/VsManageCartItemModal/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsCartBox from '@/modules/shop/components/VsCartBox/Index.vue'
import VsTrialCard from '@/modules/shop/components/VsTrialCard/Index.vue'
import Checkout from '@/modules/shop/views/checkout.vue'
import VsPaymentMethodCard from '@/modules/account/components/VsPaymentMethodCard/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import { getParameterByName } from '@/utils/queryString'
import { get, groupBy, orderBy } from 'lodash'
import {
    formatPriceName,
    formatPriceRateValue,
    calculateAndFormatItemPriceAndRate,
    formatAddress,
    addonsDriveToShop,
    DriveToShopType,
} from '@/utils/shop'
import { UserModule } from '@/store/modules/user'
import { me } from '@/api/userapi/users'
import { getCards } from '@/api/shop/cards'
import { isOldSubscription } from '@/utils/rules'
import { formatNumberWithThousandsSeparator } from '@/utils/formatter'
import { getUserCredits } from '@/api/consoleApi/user'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import VsPlanDetails from '@/modules/account/components/VsPlanDetails/Index.vue'

@Component({
    name: 'Cart',
    components: {
        VsContainer,
        VsSidebarLayout,
        VsPriceAddableCard,
        VsManageCartItemModal,
        VsCartBox,
        VsFullTopBarLayout,
        Checkout,
        VsInvoiceData,
        VsPaymentMethodCard,
        VsLoader,
        VsPlanDetails,
        VsTrialCard,
    },
})
export default class extends Vue {
    private cart: CartWithItemsPrice | null = null
    private pricesAddable: PriceWithProductRulesPreset[] = []
    private priceList: PriceListPopulated | null = null
    private hasInvoiceData = false
    private showInvoiceData = false
    private loadingSelect = false
    private loadingCheckout = false
    private loadingInvoiceData = false
    private loadingPaymentMethod = false
    private loading = false
    private canPurchase = true
    private isTrialCart = false
    private customer = null
    private defaultPaymentMethod = ''
    private cards: any[] = []
    private card: any[] = []
    private step = 'editCart' // editCart | invoiceData | checkout
    private windowTop = 0

    private userData: CustomerData = {
        email: '',
        company: '',
        phone: '',
        address: {
            city: '',
            country: 'IT',
            line1: '',
            line2: '',
            postal_code: '',
            state: '',
        },
        sdi: '',
        pec: '',
        tax: {
            country: 'IT',
            exempt: false,
            type: 'eu_vat',
            value: '',
        },
        fiscalCode: '',
        splitPayment: false,
    }

    $options: any
    $refs: any

    async beforeMount () {
        if (this.isOldSubscription) this.goToPlans()
        this.loading = true
        const step = getParameterByName('step', window.location.href) || ''
        if (step && step === 'invoice-data') this.step = 'invoiceData'
        await this.getUserAndShopUser()
        await this.updateUserCredits()
        await this.getActiveCart()
        if (!this.cart) {
            this.getPricesAddable()
        }
        this.getUserPriceList()
        this.getInvoiceData()
        await this.getCustomer()
        await this.getPaymentMethod()
        this.loading = false
    }

    get hasPaymentMethods () {
        return this.cards.length > 0
    }

    get shopUser (): ShopUser | null {
        return UserModule.shopUser
    }

    get user () {
        return UserModule.user
    }

    get showAddons () {
        if (!this.cart || (this.cart && this.cart.items.length === 0)) return !!this.shopUser?.subscription
        return this.cartRecursivity
    }

    get showPlans () {
        if (!this.cart || (this.cart && this.cart.items.length === 0)) return true
        return !this.cartPlan && this.cartRecursivity
    }

    get showCredits () {
        if (!this.cart || (this.cart && this.cart.items.length === 0)) return true
        return !this.cartPlan && !this.cartRecursivity
    }

    get cartRecursivity () {
        if (!this.cart) return false
        let recursivity: any = false
        for (const cartItem of this.cart.items) {
            if (cartItem.price.recursive) {
                recursivity = cartItem.price.rate
                break
            }
        }
        return recursivity
    }

    get addonAddable () {
        return this.pricesAddable.filter(el => !el.metadata?.rateCredits && !el.metadata?.credits)
    }

    get addonAddableRemain () {
        if (!this.cartPlan) return this.addonAddable
        const bestOption = addonsDriveToShop[(this.cartPlan.price.product.metadata?.ruleGroup) as DriveToShopType]?.bestOption || []
        const recommended = addonsDriveToShop[(this.cartPlan.price.product.metadata?.ruleGroup) as DriveToShopType]?.recommended || []
        return this.addonAddable
            .filter(el => !bestOption.includes(el.product.metadata?.ruleGroup as any) && !recommended.includes(el.product.metadata?.ruleGroup as any))
    }

    get addonBestOption () {
        if (!this.cartPlan) return []
        const bestOption = addonsDriveToShop[(this.cartPlan.price.product.metadata?.ruleGroup) as DriveToShopType]?.bestOption || []
        return this.addonAddable.filter(el => bestOption.includes(el.product.metadata?.ruleGroup as any))
    }

    get addonRecommended () {
        if (!this.cartPlan) return []
        const recommended = addonsDriveToShop[(this.cartPlan.price.product.metadata?.ruleGroup) as DriveToShopType]?.recommended || []
        return this.addonAddable.filter(el => recommended.includes(el.product.metadata?.ruleGroup as any))
    }

    get creditsAddable () {
        const credits = groupBy(
            orderBy(
                this.pricesAddable.filter(el => el.metadata?.credits && el.product.metadata?.creditType),
                'value',
                'asc',
            ),
            'product.metadata.creditType',
        )
        const sortedCredits = Object.keys(credits).sort().reduce(function (result: any, key: any) {
            result[key] = credits[key]
            return result
        }, {})
        return sortedCredits
    }

    get relatedPlanPrices (): PriceWithProductRulesPreset[] {
        if (!this.cartPlan || !this.priceList) return []
        return this.priceList.prices.filter(el => el.product.metadata?.ruleGroup && this.cartPlan?.price.product.metadata?.ruleGroup && el.product.metadata.ruleGroup === this.cartPlan.price.product.metadata.ruleGroup)
    }

    get relatedPlanOptions () {
        return orderBy(
            this.relatedPlanPrices,
            'value',
            'asc',
        ).map(el => {
            return {
                value: el._id,
                label: formatNumberWithThousandsSeparator(el.metadata?.rateCredits),
            }
        })
    }

    get populateUserItems (): CartLineItemWithPrice[] {
        if (!this.shopUser || !this.shopUser.subscription || !this.priceList) return []
        return this.shopUser.subscription.items as CartLineItemWithPrice[]
    }

    get userPlan (): CartLineItemWithPrice | null {
        if (this.cartPlan) return null
        return this.populateUserItems.find(el => el.price?.product?.strategy === 'baseSet') || null
    }

    get userAddress () {
        return formatAddress(this.userData.address)
    }

    get cartPlan (): CartLineItemWithPrice | null {
        if (!this.cart) return null
        return this.cart.items.find(el => el.price.product?.strategy === 'baseSet') || null
    }

    get isPresentRequiredUserDataInfo () {
        return this.userData.email && this.userData.tax.value && (this.userData.sdi || this.userData.pec)
    }

    get isOldSubscription () {
        return isOldSubscription(this.user.configuration.rules)
    }

    get canSeePaymentMethod () {
        return this.cartRecursivity && this.shopUser?.subscription
    }

    get cartCreatedBy () {
        if (!this.cart) return 'user'
        return this.cart.createdBy
    }

    get defaultCard () {
        return this.cards.find(el => el.id === this.defaultPaymentMethod)
    }

    get trialAvailable () {
        return this.shopUser?.trialAvailable
    }

    get canBuyTrial () {
        return this.trialAvailable && (!this.shopUser || !this.shopUser.subscription)
    }

    onScroll () {
        if (this.step !== 'editCart') return
        const cartBox = this.$refs.cartbox.$el
        const scrollButton = this.$refs.scrollToCart

        if (cartBox && scrollButton) {
            const cartBoxRect = cartBox.getBoundingClientRect()
            const isVisible = cartBoxRect.top + 100 < window.innerHeight && cartBoxRect.bottom >= 0
            if (isVisible) {
                scrollButton.style.display = 'none'
            } else {
                scrollButton.style.display = 'flex'
            }
        }
    }

    private formatPriceName (price: any) {
        return formatPriceName(price)
    }

    private formatPriceRateValue (price: any) {
        return formatPriceRateValue(price)
    }

    private calculateAndFormatItemPriceAndRate (item: any) {
        return calculateAndFormatItemPriceAndRate(item)
    }

    private async getUserPriceList () {
        try {
            const resp = await getPriceList()
            this.priceList = resp.data
        } catch (e) {
            console.log(e)
            this.priceList = null
        }
    }

    private async getActiveCart () {
        try {
            this.isTrialCart = false
            const cartFromSession: any = window.sessionStorage.getItem('trial-cart')
            const resp = await getActiveCart()
            this.cart = resp.data
            this.pricesAddable = resp.data.pricesAddable
            if (this.cart && (this.cart._id === get(JSON.parse(cartFromSession), 'idCart', '')) && this.canBuyTrial) {
                this.isTrialCart = true
                return
            }
            window.sessionStorage.removeItem('trial-cart')
        } catch (e) {
            console.log(e)
            this.cart = null
        }
    }

    private async getPricesAddable () {
        // prende tutti i prodotti validi in base alla subscription corrente dell'utente su shop api e non in base al carrello attuale
        // i prodotti validi in base al carrello sono nella chiamata del carrello attivo
        try {
            const resp = await getPricesAddable()
            this.pricesAddable = resp.data
        } catch (e) {
            console.log(e)
        }
    }

    private async removeItemToCart (item: any) {
        if (!this.cart || !this.shopUser) return
        try {
            this.isTrialCart = false
            await removeItemToCart(this.cart._id, item.priceId)
            window.sessionStorage.removeItem('trial-cart')
            await this.getActiveCart()
            if (this.cart.items.length === 0) {
                await this.getPricesAddable()
            }
        } catch (e) {
            console.log(e)
        }
    }

    private async replaceCartPlanItem (newPriceId: string) {
        if (!this.cart || !this.cartPlan) return
        this.loadingSelect = true
        const newCartItems = this.cart.items.map(el => {
            if (el.price._id === this.cartPlan?.price._id) {
                return {
                    ...el,
                    priceId: newPriceId,
                }
            }
            return el
        })
        try {
            await createCart({
                items: newCartItems,
            })
            await this.getActiveCart()
        } catch (e) {
            console.log(e)
        }
        this.loadingSelect = false
    }

    private getcartQuantityByPriceId (priceId: string) {
        if (!this.cart) return 0
        const foundItem = this.cart.items.find(el => el.priceId === priceId)
        if (!foundItem) return 0
        return foundItem.quantity
    }

    private openVsManageCartItemModal (item: { quantity: number, price: any}) {
        this.$refs.vsManageCartItemModal.openModal(
            this.cart?._id,
            item.price._id,
            this.formatPriceName(item.price),
            item.quantity,
            item.price.product.strategy === 'switchable',
        )
    }

    private async deleteAllCartItems (cart: CartWithItemsPrice) {
        try {
            await deleteAllCartItems(cart._id)
            await this.getActiveCart()
            this.isTrialCart = false
            window.sessionStorage.removeItem('trial-cart')
            await this.getPricesAddable()
            this.step = 'editCart'
        } catch (e) {
            console.log(e)
        }
    }

    private trackPhaseCompletedMixpanelEvent (eventName: string) {
        if (UserModule.Mixpanel) {
            UserModule.Mixpanel.track(
                eventName,
                {
                    distinct_id: this.user._id,
                    CartId: this.cart?._id || '',
                    Trial: this.isTrialCart,
                    HasSubscription: !!(this.shopUser && this.shopUser.subscription),
                    Interval: this.cartRecursivity ? this.cartRecursivity.type : 'una_tantum',
                },
            )
        }
    }

    setStep (step: string) {
        this.step = step
        this.trackPhaseCompletedMixpanelEvent('CartProductPhaseCompleted')
    }

    private handleSubmit () {
        if (!this.shopUser) return
        if (this.cartRecursivity && this.shopUser.subscription) {
            this.step = 'checkout'
            this.trackPhaseCompletedMixpanelEvent('CartFiscalDataPhaseCompleted')
        } else {
            this.createCheckoutSession()
        }
    }

    private async createCheckoutSession () {
        if (!this.cart) return
        this.loadingCheckout = true
        const basePath = process.env.VUE_APP_ROUTE_BASE_PATH ? process.env.VUE_APP_ROUTE_BASE_PATH : ''
        const data = {
            cartId: this.cart._id,
            thankYouPageUrl: window.location.origin + basePath + '/shop/success',
            errorPageUrl: window.location.href.split('?')[0] + '?step=invoice-data',
            trialRequested: this.isTrialCart,
        }
        try {
            const response = await checkoutSession(data)
            this.trackPhaseCompletedMixpanelEvent('CartFiscalDataPhaseCompleted')
            window.location.href = response.data.url
        } catch (e) {
            console.log(e)
            let heading = 'Qualcosa è andato storto, riprova in un secondo momento.'
            const statusCode = get(e, 'response.status', '')
            const errorCode = get(e, 'response.data.message', '')
            if (statusCode === 403) {
                heading = 'Non hai i permessi per completare questa azione'
            }

            if (statusCode === 404 && errorCode === 'ActiveCartNotFound') {
                this.$router.replace({
                    name: 'cartError',
                })
            }

            this.$root.$vsToast({
                heading,
                aspect: VsToastAspectEnum.alert,
                timeout: 5000,
            })
        }
        this.loadingCheckout = false
    }

    private async getInvoiceData () {
        this.loadingInvoiceData = true
        try {
            const resp = await getInvoiceData()
            this.userData = resp.data
            this.hasInvoiceData = true
        } catch (e) {
            this.hasInvoiceData = false
        }
        this.loadingInvoiceData = false
    }

    private async updateInvoiceData () {
        this.showInvoiceData = false
        await this.getInvoiceData()
    }

    private goBack () {
        if (this.step === 'invoiceData') {
            this.step = 'editCart'
            return
        }

        if (this.step === 'checkout') {
            this.step = 'invoiceData'
            return
        }

        this.$router.push({ name: 'plan' })
    }

    private goToPlans () {
        this.$router.push({
            name: 'subscriptions',
        })
    }

    private helpAction () {
        this.$root.$vsOpenUserSupport(true)
    }

    async getUserAndShopUser () {
        try {
            const resp = await getShopUser()
            UserModule.SET_SHOP_USER(resp.data)
        } catch (e) {
            UserModule.SET_SHOP_USER(null)
        }

        try {
            const resp = await me()
            UserModule.SET_USER(resp.data)
        } catch (e) {
            // TODO: valutare se fare redirect al logout (se non riesce a fare get me è un problema)
            UserModule.SET_USER(null as any)
        }
    }

    private async getCustomer () {
        try {
            const resp = await getInvoiceData()
            this.customer = resp.data
            this.defaultPaymentMethod = get(this.customer, 'defaultPaymentMethod', '')
        } catch (e) {
            console.log(e)
        }
    }

    private async getPaymentMethod () {
        this.loadingPaymentMethod = true
        try {
            const resp = await getCards()
            this.cards = resp.data
            await this.getCustomer()
        } catch (e) {
            console.log(e)
        }
        this.loadingPaymentMethod = false
    }

    private getFirstCard () {
        if (this.hasPaymentMethods) {
            if (!this.defaultPaymentMethod) return this.cards[0]
            return this.cards.find(el => el.id === this.defaultPaymentMethod)
        }
    }

    private async editPaymentMethod () {
        try {
            const resp = await customerPortal({
                returnUrl: window.location.href.split('?')[0] + '?step=invoice-data',
            })

            const url = this.shopUser?.subscription ? `${resp.data.url}/subscriptions/${this.shopUser.subscription.foreignId}/update-payment-method/changePaymentMethodFromHome` : resp.data.url

            window.location.href = url
        } catch (e) {
            const statusCode = get(e, 'response.status', '')
            if (statusCode === 403) {
                this.$root.$vsToast({
                    heading: 'Non hai i permessi per completare questa azione',
                    aspect: VsToastAspectEnum.alert,
                    timeout: 3000,
                })
            }
            console.log(e)
        }
    }

    private async updateUserCredits () {
        try {
            const resp = await getUserCredits()
            const credits = resp.data
            UserModule.SET_USER_CREDITS(credits)
        } catch (e) {
            console.log(e)
        }
    }

    @Watch('step', { deep: true })
    private scrollToTop () {
        const cartContainer = this.$refs.cartContainer.$el
        cartContainer.scrollIntoView({
            top: 0,
            left: 0,
            behavior: 'smooth',
        })
    }
}
