






















































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import {
    getActiveCart,
    getActiveCartUpcoming,
    getUserUpcomingInvoice,
    updateSubscription,
    getPriceList,
    getShopUser,
    renewSubscription,
} from '@/api/shop'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsSidebarLayout from '@/components/VsSidebarLayout/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsCartLineItem from '@/modules/shop/components/VsCartLineItem/Index.vue'
import { PriceListPopulated } from '@/api/shop/types/shop'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { get } from 'lodash'
import { UserModule } from '@/store/modules/user'
import { getUserCredits } from '@/api/consoleApi/user'
import { stripePromise } from '@/utils/stripe'

@Component({
    name: 'Checkout',
    components: {
        VsContainer,
        VsSidebarLayout,
        VsLoader,
        VsFullTopBarLayout,
        VsCartLineItem,
    },
})
export default class extends Vue {
    private loading = false
    private loadingUpdate = false
    private isRenewSubscription = false
    private upcoming: any = null
    private upcomingBillingCycleAnchor = ''
    private upcomingNoProration: any = null
    private userUpcomingInvoice: any = null
    private priceList: PriceListPopulated | null = null
    cart: any = null

    get user () {
        return UserModule.user
    }

    get upcomingListWithPricelistPrice () {
        if (!this.upcomingNoProration) return []
        return this.upcomingNoProration.lines.data
        // .map((list: any) => {
        //     return {
        //         ...list,
        //         priceListPrice: this.priceList?.prices.find(price => list.price.id === price.foreignId),
        //     }
        // })
    }

    get userUpcomingInvoiceListWithPricelistPrice () {
        if (!this.userUpcomingInvoice) return []
        return this.userUpcomingInvoice.lines.data
        // .map((list: any) => {
        //     return {
        //         ...list,
        //         priceListPrice: this.priceList?.prices.find(price => list.price.id === price.foreignId),
        //     }
        // })
    }

    get updatedRecursivity () {
        if (this.upcomingListWithPricelistPrice.length === 0) return false
        let recursivity: any = false
        for (const line of this.upcomingListWithPricelistPrice) {
            if (line.price.type === 'recurring') {
                recursivity = {
                    type: line.price.recurring.interval + 's',
                    value: line.price.recurring.interval_count,
                }
                break
            }
        }
        return recursivity
    }

    get currentRecursivity () {
        if (this.userUpcomingInvoiceListWithPricelistPrice.length === 0) return false
        let recursivity: any = false
        for (const line of this.userUpcomingInvoiceListWithPricelistPrice) {
            if (line.price.type === 'recurring') {
                recursivity = {
                    type: line.price.recurring.interval + 's',
                    value: line.price.recurring.interval_count,
                }
                break
            }
        }
        return recursivity
    }

    get shopUser () {
        return UserModule.shopUser
    }

    get userCredits () {
        return UserModule.userCredits
    }

    get userEmailCredits () {
        const emailCredits = this.userCredits.filter(el => el.key === 'email')
        if (emailCredits.length > 0) return emailCredits[0].value
    }

    async beforeMount () {
        this.loading = true
        await this.getUserAndShopUser()
        await this.getActiveCart()
        await this.updateUserCredits()
        await this.getPriceList()
        await this.getUserUpcomingInvoice()
        await this.getActiveCartUpcoming()
        await this.getActiveCartUpcomingForceProrationNone()
        this.loading = false
    }

    private async getActiveCartUpcoming () {
        try {
            const resp = await getActiveCartUpcoming({
                applyProrationBehavior: !this.isRenewSubscription,
                cartId: this.cart._id,
            })
            this.upcoming = resp.data.upcomingInvoice
            this.upcomingBillingCycleAnchor = resp.data.billingCycleAnchor
        } catch (e) {
            this.upcoming = null
            console.log(e)
        }
    }

    private async getActiveCartUpcomingForceProrationNone () {
        try {
            const resp = await getActiveCartUpcoming({
                applyProrationBehavior: false,
                cartId: this.cart._id,
            })
            this.upcomingNoProration = resp.data.upcomingInvoice
        } catch (e) {
            this.upcomingNoProration = null
            console.log(e)
        }
    }

    private async getActiveCart () {
        try {
            const resp = await getActiveCart()
            this.cart = resp.data
            if (!this.cart.items || this.cart.items.length === 0) {
                this.$router.replace({
                    name: 'cart',
                })
            }
            const userSubscriptionItems = get(this.shopUser, 'subscription.items', [])
            this.checkIsRenewSubscription(userSubscriptionItems, this.cart.items)
        } catch (e) {
            this.$router.replace({
                name: 'cart',
            })
        }
    }

    private checkIsRenewSubscription (userItems: any[], cartItems: any[]) {
        if (userItems.length === 0) return
        if (cartItems.length === userItems.length) {
            const mappedCartItems = cartItems.map((item: any) => item.priceId)
            const mappedUserItems = userItems.map((item: any) => item.priceId)
            if ((mappedCartItems.sort().join(',') === mappedUserItems.sort().join(',')) && this.userEmailCredits === 0) {
                this.isRenewSubscription = true
            }
        }
    }

    private async getPriceList () {
        try {
            const resp = await getPriceList()
            this.priceList = resp.data
        } catch (e) {
            this.priceList = null
            console.log(e)
        }
    }

    private async getUserUpcomingInvoice () {
        try {
            const resp = await getUserUpcomingInvoice()
            this.userUpcomingInvoice = resp.data
        } catch (e) {
            this.userUpcomingInvoice = null
            if (get(e, 'response.data.message', '') === 'SubscriptionNotFoundError') {
                this.$router.replace({
                    name: 'cart',
                })
            }
        }
    }

    private async updateSubscription () {
        if (this.upcoming.total <= 0 && this.upcoming.amount_due === 0) return
        this.loadingUpdate = true
        try {
            let resp
            if (this.isRenewSubscription) {
                resp = await renewSubscription(this.cart._id)
            } else {
                resp = await updateSubscription(this.cart._id)
            }
            // When the customer clicks on the button, redirect them to Checkout.
            const stripe = await stripePromise
            if (!stripe) return
            const result = await stripe.confirmCardPayment(resp.data.latest_invoice.payment_intent.client_secret)

            if (result.error) {
                this.$root.$vsToast({
                    heading: 'Non è possibile procedere con l\'operazione.',
                    message: 'Pagamento non completato',
                    aspect: VsToastAspectEnum.alert,
                    timeout: 8000,
                    closable: true,
                })
            } else {
                this.$router.push({
                    name: 'cartSuccess',
                })
            }
        } catch (e) {
            let heading = 'Errore durante la modifica dell\'abbonamento.'
            const statusCode = get(e, 'response.status', '')
            if (statusCode === 403) {
                heading = 'Non hai i permessi per completare questa azione'
            }

            let message = ''
            const errorMessage = get(e, 'response.data.message', '')
            if (errorMessage === 'ErrorDuringUpdateSubscriptionOnStripe-resource_missing') {
                message = 'Non hai un metodo di pagamento associato, impossibile completare l\'operazione'
            }
            if (errorMessage === 'ErrorDuringUpdateSubscriptionOnStripe-subscription_payment_intent_requires_action') {
                message = 'Il metodo di pagamento utilizzato per effettuare la modifica non è valido in quanto richiede una verifica da parte del proprietario'
            }

            const errorCode = get(e, 'response.data.message', '')

            if (statusCode === 404 && errorCode === 'ActiveCartNotFound') {
                this.$router.replace({
                    name: 'cartError',
                })
                return
            }

            this.$root.$vsToast({
                heading,
                message,
                aspect: VsToastAspectEnum.alert,
                timeout: 8000,
                closable: true,
            })
        }
        this.loadingUpdate = false
    }

    async getUserAndShopUser () {
        try {
            const resp = await getShopUser()
            UserModule.SET_SHOP_USER(resp.data)
        } catch (e) {
            UserModule.SET_SHOP_USER(null)
        }
    }

    private helpAction () {
        this.$root.$vsOpenUserSupport(true)
    }

    private async updateUserCredits () {
        try {
            const resp = await getUserCredits()
            const credits = resp.data
            UserModule.SET_USER_CREDITS(credits)
        } catch (e) {
            console.log(e)
        }
    }
}
