






















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

export enum VsNumberSizeEnum {
    'small' = 'small',
    'medium' = 'medium',
    'large' = 'large',
}

@Component({
    name: 'VsNumber',
})
export default class extends Vue {
    @Prop({ default: 0, required: false, type: Number }) value!: number
    @Prop({ default: VsNumberSizeEnum.medium, required: false }) size?: VsNumberSizeEnum
    @Prop({ default: undefined, required: false, type: Number }) min?: number
    @Prop({ default: undefined, required: false, type: Number }) max?: number
    @Prop({ default: 1, required: false, type: Number }) step!: number
    @Prop({
        required: false,
        default: undefined,
    }) identifier?: string

    @Prop({
        required: false,
        default: '',
    }) tooltip?: string

    @Prop({
        required: false,
        default: '',
    }) label?: string

    @Prop({
        required: false,
        default: false,
    }) disabled?: boolean

    @Prop({
        required: false,
        default: false,
    }) readonly?: boolean

    @Prop({
        required: false,
        default: false,
    }) error?: boolean

    @Prop({
        required: false,
        default: '',
    }) caption?: string

    @Prop({
        required: false,
        default: '',
    }) errorMessage?: string

    @Prop({
        required: false,
        default: false,
    }) labelHidden?: boolean

    get iconSize () {
        if (!this.size || this.size === VsNumberSizeEnum.medium) return 5
        if (this.size === VsNumberSizeEnum.small) return 5
        if (this.size === VsNumberSizeEnum.large) return 6
    }

    get numberDynamicCalsses () {
        return {
            'vs-body-large': true,
            'vs-text-center': true,
            'vs-mx-2': true,
            'vs-h500': this.size === VsNumberSizeEnum.medium || !this.size,
            'vs-h600': this.size === VsNumberSizeEnum.large,
            'vs-h400': this.size === VsNumberSizeEnum.small,
        }
    }

    get componentsDynamicClass () {
        return {
            label: {
                'vs-block': true,
                'vs-max-fit-content': true,
                'vs-w-full': true,
                'vs-mb-1': this.size === VsNumberSizeEnum.small,
                'vs-mb-2': this.size === VsNumberSizeEnum.medium || this.size === VsNumberSizeEnum.large,
            },
            caption: {
                'vs-text-alert-500': this.error,
                'vs-max-fit-content': true,
                'vs-mt-1': this.size === VsNumberSizeEnum.small,
                'vs-mt-2': this.size === VsNumberSizeEnum.medium || this.size === VsNumberSizeEnum.large,
            },
        }
    }

    @Watch('value', { immediate: true })
    private verifyLimits (value: number) {
        if (this.max && value > this.max) this.$emit('input', this.max)
        if (this.min && value < this.min) this.$emit('input', this.min)
    }

    private addValue () {
        let newValue = this.value + this.step
        if (this.max && newValue > this.max) newValue = this.max
        this.$emit('input', newValue)
    }

    private removeValue () {
        let newValue = this.value - this.step
        if (this.min && newValue < this.min) newValue = this.min
        this.$emit('input', newValue)
    }
}
